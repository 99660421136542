
class responseModel {
    id;
    licensePlate;
    departureTime;
    destination;
    arrivalTime;
    State;
    busType;
}

export default responseModel;